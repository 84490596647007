<template>
	<div>
		<myheader></myheader>
		<div class="bg"></div>
		<div class="main">

			<div class="title">
				<p>游记旅拍</p>
				<img src="../../assets/other/right.png" alt="">
				<p class="active">领队列表</p>
			</div>
			<div class="guidelist">
				<div class="guide" v-for="(item,i) in teamerlist" :key="i" @click="getinfo(item.id)">
					<div class="img">
						<img :src="item.photo" alt="">
					</div>
					<div class="guideinfo">
						<div class="g1">
							<h4>{{item.real_name}}</h4>
							<p v-if="item.is_month_star==1">本月之星</p>
						</div>
						<div class="g2">
							<p>用户评分</p>
							<p class="num">{{item.scores}}</p>
						</div>
						<div class="g3">
							{{item.text_note | wuellipsis(53)}}
						</div>
					</div>
				</div>
				<!-- <div class="line"></div> -->
			</div>

		</div>
		<el-dialog  :visible.sync="dia"  title="领队介绍" width="1000px">
			<div class="guidedia">
				<!-- <div class="title">
					<h4>领队介绍</h4>
					<img src="../../assets/public/close2.png" alt="">
				</div> -->
				<div class="content">
					<div class="left">
						<div class="img">
							<img :src="teamerinfo.photo" alt="">
						</div>
						<p class="p1">{{teamerinfo.real_name}}</p>
						<p class="p2" v-if="teamerinfo.is_month_star==1">本月之星</p>
						<p class="p3">用户评分 <span>{{teamerinfo.scores}}</span></p>
						<div class="pic">
							<div>
								<img src="../../assets/shot/zhan.png" alt="" @click="zhan" v-if="!teamerinfo.is_leade">
								<img src="../../assets/shot/zhan2.png" alt="" @click="zhan" v-if="teamerinfo.is_leade" >
								<p>{{teamerinfo.leade_count}}</p>
							</div>
							<div>
								<img src="../../assets/shot/fx.png" alt="">
								<p>分享</p>
							</div>
						</div>
					</div>
					<div class="right">
						<p>
							{{teamerinfo.content}}
						</p>

					</div>
				</div>

				<div class="line"></div>

				<div class="gu">
					<h4>近期带团</h4>
					<div class="gulist">
						<div class="guitem" v-for="(item,i) in usedlist" :key="i">
							<img :src="item.goods_pic" alt="">
							<p class="g1">{{item.goods_name | ellipsis}}</p>
							<p class="g2">出团日期：{{item.buy_date}}</p>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<myfooter></myfooter>
		<myright></myright>
	</div>

</template>

<script>
import {teamerListAPI,teamerInfoAPI,zhanteamerAPI} from "@/api/trivialShot"
import myheader from '@/components/header.vue'
import myfooter from '@/components/footer.vue'
import myright from '@/components/right.vue'

export default {

  components: {
    myheader, myfooter, myright
  },
  data () {
    return {
      dia: false,
		teamerlist:[],
		teamerinfo:{},
		usedlist:[],
		id:null
    }
  },
    filters:{
	ellipsis: function(value) {
			if (!value) return "";
			if (value.length >20) {
			return value.slice(0,19) + "...";
			}
			return value;
	}
  },
  created () {
	  this.getteamerlist()
  },
  methods: {
    getinfo (id) {
      this.id=id
	  this.getinfo(id)
    },
	getteamerlist(){
		teamerListAPI().then(res=>{
			this.teamerlist=res.cateList
		})
	},
	getinfo(id){
		teamerInfoAPI({id}).then(res=>{
			this.teamerinfo=res.view
			this.usedlist=res.cateList
			this.dia = true
		})
	},
	zhan(){
		var obj={
			leader_id:this.teamerinfo.id,
			type:this.teamerinfo.is_leade ? "2" : "1"
		}
		zhanteamerAPI(obj).then(res=>{
			this.getinfo(this.teamerinfo.id)
		})
	}
  }
}
</script>

<style lang="scss" scoped="scoped">
.bg{
		height: 10px;
		min-width: 1200px;
		width: 100%;
		background: linear-gradient(#ececec,#fff);
	}
.main{
	width: 1200px;
	margin: 0 auto;

	.title{
		display: flex;
		align-items: center;
		margin: 10px 0 15px;
		font-size: 15px;
		p{
			color: #666666;
		}
		.active{
			color: #FF7C00;
		}
		line-height: 21px;
		img{
			width: 10px;
			height: 11px;
			margin: 0 10px;
		}
	}

	.guidelist{
		display: flex;
		flex-wrap: wrap;
		.guide{
			display: flex;
			width: 550px;
			margin:15px 35px 15px 0;
			.img{
				width: 138px;
				height: 138px;
				opacity: 1;
				border-radius: 5px;
				margin-right: 20px;
				box-shadow: rgba(147,147,147,0.20) 5px 5px 5px;
				img{
					width: 138px;
					min-height: 138px;
					overflow: hidden;
					border-radius: 5px;
				}
			}
			.guideinfo{
				>div{
					display: flex;
					margin-bottom: 15px;
					align-items: center;
				}
				.g1{
					h4{
						font-weight: 400;
						font-size: 20px;
						color: #333333;
						line-height: 28px;
					}
					p{
						width: 60px;
						height: 23px;
						text-align: center;
						border: 1px solid #ff7c00;
						border-radius: 3px;
						font-size: 12px;
						line-height: 23px;
						margin-left: 10px;
						color: #FF7C00;
					}
				}

				.g2{
					p{
						font-size: 16px;
					}

					.num{
						color: #FF7C00;
						margin-left: 15px;
					}
				}
				.g3{
					font-size: 14px;
					line-height: 24px;
				}
			}
		}

	}
}
.guidedia{

	// padding: 10px;
	.content{
		display: flex;
		justify-content: space-between;

		// background-color: red;
		.left{
			width: 138px;
			margin-right: 40px;
			text-align: center;
			.img{
				width: 138px;
				// height: 208px;
				overflow: hidden;
				border-radius: 5px;

				box-shadow: rgba(147,147,147,0.20) 5px 5px 5px;
				img{
					width: 138px;
					// min-height: 208px;
					border-radius: 5px;
				}
			}
			.p1{
				font-size: 20px;
				color: #333333;
				line-height: 28px;
				margin-top: 15px;
			}
			.p2{

				width: 60px;
				height: 23px;
				margin: 10px auto;
				text-align: center;
				border: 1px solid #ff7c00;
				border-radius: 3px;
				font-size: 12px;
				line-height: 23px;
				color: #FF7C00;
			}
			.p3{
				span{
					color: #FF7C00;
					margin-left:8px;
				}
			}
			.pic{
				display: flex;
				margin-top: 20px;
				justify-content: space-around;
				img{
					width: 40px;
					height: 40px;
				}
				p{
					font-size: 12px;
				}
			}

		}
		.right{
			flex: 1;
			height: 480px;
			background: #f9f9f9;
			border-radius: 6px;
			padding: 10px;
			overflow: hidden;
			p{
				color: #666666;
				line-height: 26px;
				text-indent: 2em;
				font-size: 14px;
			}

		}
	}
	.line{
		height: 1px;
		width: 100%;
		border-bottom: 2px dashed   #e6e6e6;
		margin: 15px 0;
	}
	.gu{
		height: 205px;
		h5{
			font-size: 20px;
			color: #333333;
			line-height: 28px;
			margin-top: 15px;
		}
		// .gulist::-webkit-scrollbar{
		// 	display: none;
		// }

		.gulist{
			height: 205px;
			display: flex;
			// overflow-x: scroll;
			box-sizing: border-box;
			/*设置横向滚动*/
				overflow-x: scroll;
				/*禁止纵向滚动*/
				overflow-y: hidden;
			.guitem{
				margin: 5px;
				width: 230px;
				height: 165px;
				opacity: 1;
				background: #f5f5f5;
				border-radius: 4px;

				img{
					width: 230px;
					height: 110px;
					border-radius: 4px 4px 0 0;
				}
				.g1{
					color: #333333;
					font-weight: 500;
					line-height: 24px;
					font-size: 12px;
					text-indent: 8px;
				}
				.g2{
					color: #666;
					line-height: 24px;
					font-size: 12px;
					text-indent: 8px;
				}

			}
		}
	}
}
</style>
