import myRequest from '../http/index.js'
//接口：领队列表
export function teamerListAPI(params){
    return myRequest({
        url:"index.php/index/Article/ArticleList",         
        params,
        method:"post"
      })
}
//接口：领队详情
export function teamerInfoAPI(params){
  return myRequest({
      url:"index.php/index/Article/ArticleView",         
      params,
      method:"get"
    })
}
//领队（点赞、取消点赞）
export function zhanteamerAPI(params){
  return myRequest({
      url:"index.php/index/Article/giveLeader",         
      params,
      method:"post"
    })
}
//接口：游记列表
export function takeListAPI(params){
    return myRequest({
        url:"index.php/index/Article/userArticleTakeList",         
        params,
        method:"get"
      })
}
//接口：游记详情
export function takeViewAPI(params){
  return myRequest({
      url:"index.php/index/Article/userArticleTakeView",         
      params,
      method:"get"
    })
}
//接口：游记轮播
export function BankList2API(params){
  return myRequest({
      url:"index.php/index/index/BankList2",         
      params,
      method:"get"
    })
}
//收藏/取消收藏
export function collectionTakeDelAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/collectionTakeDel",         
      params,
      method:"get"
    })
}

